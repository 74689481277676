import * as styles from './modal.module.scss'

import { graphql, useStaticQuery } from 'gatsby'

import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

const PopupModal = ({ open }) => {
  const { image } = useStaticQuery(graphql`
    {
      image: file(relativePath: {eq: "popup-kochbuch-deutsche-kueche.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            quality: 65
            width: 323
          )
        }
      }
    }
  `)

  return (
    <div className={styles.modal} data-open={open}>
      <div className={styles.title}>🎉UNSER 2. KOCHBUCH IST DA! 🎉</div>
      <div className={styles.body}>"Deutsche Küche Vegan" heißt unser 2. Kochbuch und beinhaltet 101 vegane Rezepte aus der deutschen Küche. Ab sofort bei uns im Shop bestellbar.</div>
      <div className={styles.image}>
        <GatsbyImage
          image={image?.childImageSharp?.gatsbyImageData}
          alt="Unser Adventskalender ist zurück!"
          style={{ maxWidth: "100%" }}
        />
      </div>
      <a target='_blank' className={styles.button} href="https://shop.zuckerjagdwurst.com/products/deutsche-kuche-vegan-kochbuch-mit-exklusivem-mini-e-book-und-gutschein-printable-fur-weihnachten">Zum Shop</a>
    </div>
  )
}

export default PopupModal
